@import url("https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=cyrillic");
@import url("https://fonts.googleapis.com/css?family=Lato:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap&subset=cyrillic");
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:opsz,wght@6..12,300;6..12,400;6..12,500;6..12,600;6..12,700&display=swap");

html {
  margin: 0;
  padding: 0;
  background: #181818;
}

body {
  height: 100%;
  margin: 0;
  color: #ffffff;
  font-size: 14px;
  overflow-x: hidden;
}

#root {
  position: relative;
}

* {
  box-sizing: border-box;
  font-family: "Lato", "Nunito Sans", sans-serif;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
    border-radius: 12px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
  }
  &::-webkit-scrollbar-button {
    display: none;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000000;
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
  transition: background-color 5000s ease-in-out 0s;
  border-radius: 12px;
  &::first-line {
    font-size: 16px;
  }
}
.border-gradient {
  border: 2px solid;
  border-color: linear-gradient(180deg, #49ac5b 0%, #30488f 100%);
}

@media only screen and (max-width: 960px) {
}
